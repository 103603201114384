import {ReactComponent as FacebookLogo} from '../assets/icons/facebook.svg';
import {ReactComponent as InstagramLogo} from '../assets/icons/instagram.svg';

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__inner">
                    <div>
                        <p><strong>Bro Design Studio</strong></p>
                        <address>Studio 1, 6a Kew Road, Birkdale, Southport, Merseyside PR84HH</address>
                    </div>
                    <ul>
                        <li>
                            <a href="https://www.instagram.com/brodesignuk" target="_blank" rel="noopener noreferrer">
                                <InstagramLogo/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/brodesignuk/" target="_blank" rel="noopener noreferrer">
                                <FacebookLogo/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
