import React from 'react';

function PageVideo({src}) {
    return (
        <video autoPlay muted loop playsInline>
            <source src={src} type="video/mp4"/>
        </video>
    );
}

export default PageVideo;