import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import PageVideo from "../components/PageVideo";
import video from "../assets/videos/Bro_site_contact_movie_6_compressed.mp4";

function Contact(props) {
    const [thankYouDisplay, setThankYouDisplay] = useState(0);
    const [formMessage, setFormMessage] = useState('');
    const [isFormLoading, setFormLoading] = useState(0);

    const {
        setError,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
            your_email: '',
            your_message: ''
        }
    });

    const onError = () => setFormMessage('Please complete all fields');

    const onSubmit = data => {
        setFormLoading(1);
        const formData = new FormData();

        formData.append('action', 'send_mail');

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        fetch(`${process.env.REACT_APP_SITE_URL}send-mail2.php`, {
            method: 'POST',
            body: formData
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    setThankYouDisplay(1);
                } else if (data.errors) {
                    Object.keys(data.errors).map(inputName => {
                        setError(inputName, {
                            message: data.errors[inputName]
                        })
                    })
                }
                setFormLoading(0);
            });
    }

    return (
        <section className="section-home-logo-stick">
            <div className="container">
                <div className="section-home-logo-stick__columns">
                    <div>
                        <PageVideo src={video}/>
                    </div>
                    <div>
                        <div className="home-section">
                            {!thankYouDisplay ? (
                                <div className="home-section__content reset-last-child">
                                    <h1 className="text-primary h2" data-aos="fade-up">If you want to see how Bro could
                                        help
                                        you, please get in touch for a free consultation</h1>
                                    <form data-loading={isFormLoading ? true : false} className="lets-talk-form"
                                          data-aos="fade-up" onSubmit={handleSubmit(onSubmit, onError)}>
                                        <div
                                            className={`form-group form-group--float-label ${errors.first_name && 'invalid'}`}>
                                            <input type="text" placeholder="First Name*" id="first_name"
                                                   className="form-control"
                                                   {...register("first_name", {required: true})}
                                            />
                                            <label htmlFor="first_name">First Name*</label>
                                            {errors.first_name && <div
                                                className="form-group__error-message">{errors.first_name.message}</div>}
                                        </div>
                                        <div
                                            className={`form-group form-group--float-label ${errors.last_name && 'invalid'}`}>
                                            <input type="text" placeholder="Last Name*" id="last_name"
                                                   className="form-control"
                                                   {...register("last_name", {required: true})}
                                            />
                                            <label htmlFor="last_name">Last Name*</label>
                                            {errors.last_name && <div
                                                className="form-group__error-message">{errors.last_name.message}</div>}
                                        </div>
                                        <div
                                            className={`form-group form-group--float-label ${errors.your_email && 'invalid'}`}>
                                            <input type="email" placeholder="Email Address*" id="your_email"
                                                   className="form-control"
                                                   {...register("your_email", {required: true})}
                                            />
                                            <label htmlFor="your_email">Email Address*</label>
                                            {errors.your_email && <div
                                                className="form-group__error-message">{errors.your_email.message}</div>}
                                        </div>
                                        <div
                                            className={`form-group form-group--float-label ${errors.your_message && 'invalid'}`}>
                                            <textarea placeholder="How can we help?" id="your_message"
                                                      className="form-control"
                                                      {...register("your_message", {required: true})}
                                            ></textarea>
                                            <label htmlFor="your_message">How can we help?*</label>
                                            {errors.your_message && <div
                                                className="form-group__error-message">{errors.your_message.message}</div>}
                                        </div>

                                        {formMessage && <div className="form-error-message">{formMessage}</div>}
                                        <button disabled={isFormLoading} type="submit"
                                                className="btn btn--filled btn--orange">{isFormLoading ? 'SENDING...' : 'SUBMIT'}</button>
                                    </form>
                                </div>
                            ) : (
                                <div className="home-section__content reset-last-child">
                                    <div className="text-primary h2">Thanks for getting in touch.</div>
                                    <p>One of our team will be in contact shortly.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;