import logo from '../assets/images/logo.svg'
import {NavLink} from "react-router-dom";

function Header() {
    return (
        <header className="header">
            <div className="container">
                <div className="header__inner">
                    <NavLink to='/' className="header__logo">
                        <img src={logo} alt="Bro"/>
                    </NavLink>
                    <NavLink to='/contact' className="btn btn--orange btn--outline">Talk to us</NavLink>
                </div>
            </div>
        </header>
    );
}

export default Header;
