import PageVideo from "../components/PageVideo";
import video from "../assets/videos/Bro_site_intro_movie_2_compressed.mp4";
import quoteData from "../json/quotes.json";
import Quote from "../components/Quote";
import HomeIcons from "../components/HomeIcons";

function Home(props) {
    return (
        <>
            <section className="section-home-logo-stick">
                <div className="container">
                    <div className="section-home-logo-stick__columns">
                        <div>
                            <PageVideo src={video}/>
                        </div>
                        <div>
                            <div className="home-section">
                                <h1 data-aos="fade-up">Bro is a creative design studio with over 20 years’ experience
                                    specialising in digital design and web development</h1>

                                <a href="#web-design" className="mouse_scroll">
                                    <div className="mouse">
                                        <div className="wheel"></div>
                                    </div>
                                    <div>
                                        <span className="m_scroll_arrows unu"></span>
                                        <span className="m_scroll_arrows doi"></span>
                                        <span className="m_scroll_arrows trei"></span>
                                    </div>
                                </a>

                            </div>
                            <div className="mobile-scroll-nav">
                                <HomeIcons hasLinks="1"/>
                            </div>
                            <div className="home-section" id="web-design">
                                <HomeIcons activeIndex="1"/>
                                <div className="home-section__content reset-last-child">
                                    <h2 className="h1" data-aos="fade-left">Web design</h2>
                                    <p className="text-primary text-highlight" data-aos="fade-up">Our UK based team
                                        makes websites ranging from brochure/information giving sites to full blown
                                        marketplaces and platforms. We have a track record of delivering results.</p>
                                    <ul data-aos="fade-up">
                                        <li>Tailormade web development</li>
                                        <li>WordPress powered CMS</li>
                                        <li>Support and maintenance</li>
                                        <li>UX/UI design and ideation</li>
                                        <li>Plugin creation and third-party integrations</li>
                                        <li>SEO optimisation</li>
                                        <li>Content upload</li>
                                        <li>Carbon neutral Cloud hosting</li>
                                        <li>Domain and SSL registration and management</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="home-section" id="brand-creation">
                                <HomeIcons activeIndex="2"/>
                                <div className="home-section__content reset-last-child">
                                    <h2 className="h1" data-aos="fade-left">Brand creation</h2>
                                    <p className="text-primary text-highlight" data-aos="fade-up">Are you a startup
                                        business, looking to make that important first impression or an established
                                        company seeking to refresh its identity? Whichever it is, we relish the
                                        challenge and create logos that become the cornerstone to a company’s brand.</p>
                                    <p className="mb-0" data-aos="fade-up"><strong>What we do:</strong></p>
                                    <ul data-aos="fade-up">
                                        <li>Logo design</li>
                                        <li>Brand guidelines</li>
                                        <li>Design for print</li>
                                        <li>Social Media</li>
                                        <li>Packaging & label design</li>
                                        <li>Signage design & installation</li>
                                        <li>Exhibition display</li>
                                        <li>Vehicle livery</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="home-section" id="reviews">
                                <HomeIcons activeIndex="3"/>
                                {quoteData.length ? (
                                    <div className="home-section__content reset-last-child">
                                        <h2 className="h1" data-aos="fade-left">Kind words</h2>
                                        <div className="testimonials-list">
                                            {quoteData.map((item, index) => (<Quote key={index} data={item}/>))}
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;