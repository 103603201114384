import React from 'react';

function NotFound(props) {
    return (
        <section className="section-home-logo-stick">
            <div className="container">
                <h1 style={{textAlign:"center",fontSize:"20vw"}}>404</h1>
            </div>
        </section>
    );
}

export default NotFound;