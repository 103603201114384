import React from 'react';

import {ReactComponent as WebDesignIcon} from "./../assets/icons/web-design.svg";
import {ReactComponent as BrandCreationIcon} from "./../assets/icons/brand-creation.svg";
import {ReactComponent as ReviewsIcon} from "./../assets/icons/reviews.svg";

function HomeIcons({activeIndex = 0, hasLinks = 0}) {
    return (
        <ul className="section-icons" data-aos="zoom-in">
            <li className={activeIndex === '1' ? 'active-item' : ''}>
                {hasLinks ? (
                    <a href="#web-design" className="icon-container">
                        <WebDesignIcon/>
                    </a>
                ) : (
                    <div className="icon-container">
                        <WebDesignIcon/>
                    </div>
                )}
            </li>
            <li className={activeIndex === '2' ? 'active-item' : ''}>
                {hasLinks ? (
                    <a href="#brand-creation" className="icon-container">
                        <BrandCreationIcon/>
                    </a>
                ) : (
                    <div className="icon-container">
                        <BrandCreationIcon/>
                    </div>
                )}
            </li>
            <li className={activeIndex === '3' ? 'active-item' : ''}>
                {hasLinks ? (
                    <a href="#reviews" className="icon-container">
                        <ReviewsIcon/>
                    </a>
                ) : (
                    <div className="icon-container">
                        <ReviewsIcon/>
                    </div>
                )}
            </li>
        </ul>
    );
}

export default HomeIcons;