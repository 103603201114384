import AOS from 'aos';
import 'aos/dist/aos.css';
import './scss/main.scss'
import {Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import {useEffect} from "react";


function App() {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <div className="App">
            <Header/>
            <Routes>
                <Route path='/' Component={Home}></Route>
                <Route path='/contact' Component={Contact}></Route>
                <Route path='send-mail.php'></Route>
                <Route path='*' Component={NotFound}></Route>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
