import React from 'react';

function Quote({data}) {
    return (
        <figure data-aos="fade-up">
            <blockquote className="reset-last-child" dangerouslySetInnerHTML={{__html: data.text}}></blockquote>
            <figcaption>{data.author}</figcaption>
        </figure>
    );
}

export default Quote;